/*global google*/
import React, { Component } from "react";
import styled from 'styled-components'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

const MapWrapper = styled.div`
    height: 100%;
    position: relative;
`;

export class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {}
        };
    }
    onMarkerClick(props, marker, e) {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }
    render() {
        if (!this.props.google) {
            return <div>Loading...</div>;
        }

        return (
            <MapWrapper>
                <Map style={{ filter: 'grayscale(100%)' }}
                    google={this.props.google}
                    zoom={16}
                    initialCenter={{
                        lat: 50.29051379002406,
                        lng: 18.677906000000004,
                    }}>

                    <Marker
                        onClick={this.onMarkerClick}
                        name={"Tu jesteśmy"}
                    /*
                    icon={{
                        url: "/img/icon.svg",
                        anchor: new google.maps.Point(32, 32)   ,
                        scaledSize: new google.maps.Size(64, 64)
                    }}
                    */
                    />
                    <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                    >
                        <div>
                            <h5>{this.state.selectedPlace.name}</h5>
                        </div>
                    </InfoWindow>
                </Map>
            </MapWrapper>
        );
    }
}
export default GoogleApiWrapper({
    apiKey: "AIzaSyB8w3Lz8w7B9IL7lxvtMtbILrNNcfGPLGg",
    v: "3.30"
})(MapContainer);
