import React, { Fragment } from "react"
import styled from "styled-components"
import { down, up } from "styled-breakpoints"
//DATA
import { info, godziny } from "../assets/data/kontakt"
//STYLES
import { H1, H4, Text, LabelBW } from "../utils/typo"
import { Logo, Section, ContainerMob } from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/SEO"
import MapContainer from "../components/googleMap/GoogleMap"
import Layout from "../layout/layout"

const Baner = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 7rem;

  ${down("sm")} {
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }

  p {
    white-space: pre-wrap;
  }

  iframe {
    filter: grayscale(100%);
    ${down("sm")} {
      display: none;
    }
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const MapMobile = styled.div`
  margin-top: 4rem;
  > div {
    height: 300px;
  }

  iframe {
    filter: grayscale(100%);
    height: 300px;
  }

  ${up("md")} {
    display: none;
  }
`

const GodzinyOtwarcia = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
`

const Kontakt = () => {
  return (
    <div>
      <Seo title="Kontakt" slug="/kontakt" />
      <Layout>
        <Logo />
        <Section pt="6rem" pb="3rem">
          <ContainerMob>
            <Baner>
              {/* <MapContainer /> */}
              <iframe
                title="map"
                width="100%"
                height="100%"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src="https://www.openstreetmap.org/export/embed.html?bbox=18.676784634590152%2C50.28876344904422%2C18.68032515048981%2C50.29032290276343&amp;layer=mapnik&amp;marker=50.28954318229204%2C18.678554892539978"
              />

              <TextWrapper>
                <LabelBW mb="1rem">Kontakt</LabelBW>
                <H1>Zostańmy w kontakcie!</H1>
                <Text>— Zapraszamy do kontaktu</Text>
                <H4 mt="2rem">+48 {info.tel}</H4>
                <H4>{info.mail}</H4>

                <LabelBW mb="1rem" mt="3rem">
                  Godziny otwarcia
                </LabelBW>
                <GodzinyOtwarcia>
                  {godziny.slice(0, 5).map(dzien => {
                    return (
                      <Fragment key={dzien.day}>
                        <Text weight="700">{dzien.day}</Text>
                        <Text>
                          {dzien.godzinaOd} - {dzien.godzinaDo}
                        </Text>
                      </Fragment>
                    )
                  })}
                </GodzinyOtwarcia>

                <LabelBW mb="1rem" mt="3rem">
                  Adres
                </LabelBW>
                <p>
                  <b>{info.wydzial}</b>
                </p>
                <p>
                  ul. {info.ulica} {info.nrBud}
                </p>
                <p>
                  {info.kod} {info.miasto}
                </p>
                <p>{info.woj}</p>
                <p>{info.kraj}</p>

                <LabelBW mb="1rem" mt="3rem">
                  Firma
                </LabelBW>
                <p>
                  <b>NIP: </b>
                  {info.nip}
                </p>
                <p>
                  <b>REGON: </b>
                  {info.regon}
                </p>
              </TextWrapper>
            </Baner>

            <MapMobile>
              {/* <MapContainer /> */}
              <iframe
                title="mapMobile"
                width="100%"
                height="100%"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src="https://www.openstreetmap.org/export/embed.html?bbox=18.676784634590152%2C50.28876344904422%2C18.68032515048981%2C50.29032290276343&amp;layer=mapnik&amp;marker=50.28954318229204%2C18.678554892539978"
              />
            </MapMobile>
          </ContainerMob>
        </Section>
      </Layout>
    </div>
  )
}
export default Kontakt
